body {
    margin: 0;
    padding: 0;
    background: #AAA;
    color: #0047BB;
    overflow: hidden;
}

.app-outer {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    max-width: 100%;

    * {
        position: relative;
        box-sizing: border-box;
    }
}

#app-container {
    width: 1366px;
    height: 768px;
    min-width: 1366px;
    min-height: 768px;
    background: #FFF;
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 30%);
}
